import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Container from '../components/mainContent/container'
import ImagenGrabados from '../components/imageComponents/imagenGrabados'

const IndexPage = () => (
  <Layout>
    <SEO title='Grabados' />
    <Header siteTitle='GRABADOS' />

    <Container>
      <div className='container'>
        <div className='row justify-content-md-center'>


        <div className='col-sm-12 col-md-5'>
            <ImagenGrabados />
          </div>

          <div className='col-sm-12 col-md-7  col-lg-7 conmargen'>

            <p> <b><span className="oro"> OR-PRINTER </span></b>   pone a disposición de sus clientes el envío de
              grabados en 24 / 48 horas para grabados de 1,75 mm. 3 mm, 7mm, latón y relieve.
            </p><p>
            <b><span className="oro"> OR-PRINTER </span></b> proporciona  grabados:
              </p>
              <ul className='aplicaciones'>
                <li>Metálicos</li>
                <li>Zinc</li>
                <li>Magnesio</li>
                <li>Polímero</li>
                <li>Latón</li>
                </ul> 
               
            <p>Póngase en <Link   className="maquinas" to="/Contacto/">  Contacto</Link> con nosotros y le asesoraremos sobre la mejor opción. 
            Somos proveedores de grabados para una ámplia lista de clientes activos y con una dilatada experiencia.
            
            </p>
          </div>
        </div>

      </div>
    </Container>
  </Layout>
)

export default IndexPage
